<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    <div class="login-container">
      <div class="login-box">
        <div class="login-logo">
          <img src="/ssr/img/tiktok_voice_logo.svg" alt="TikTok AI Voice Generator logo" height="30" width="40" />
          <img src="/ssr/img/tiktok_voice_word.svg" alt="TikTok AI Voice Generator logo" height="60" width="160" />
        </div>
        <h1 class="login-title">TikTok Voice Generator</h1>
        <div v-if="!isLoggedIn">
          <h2 class="login-subtitle">Login to access your TikTok Voice Generator account</h2>
          <div ref="googleSignInButton" class="google-signin-button"></div>
          <p v-if="error" class="error-message">{{ error }}</p>
        </div>
        <div v-else>
          <h2 class="login-subtitle">You are already logged in</h2>
          <button @click="redirectToHome" class="redirect-button">Go to Home</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VoiceHeader from '../components/VoiceHeader.vue';

export default {
  name: "login",
  data() {
    return {
      error: null
    }
  },
  head() {
    return {
      'title': this.$i18n.t('txt2voice.title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description'),
    }
  },
  components: {
    VoiceHeader
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    initGoogleSignIn() {
      if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
        try {
          google.accounts.id.initialize({
            client_id: '304803644975-lmsl5t2frh2vniu0f9j9ua9pbso86fg2.apps.googleusercontent.com',
            callback: this.handleCredentialResponse,
            ux_mode: 'popup',
          });
          google.accounts.id.renderButton(
            this.$refs.googleSignInButton,
            { theme: 'filled_blue', size: 'large', text: 'signin_with',  locale: 'en' }
          );
        } catch (error) {
          console.error("Error initializing Google Sign-In:", error)
        }
      } else {
        console.error("Google Sign-In SDK not loaded!")
      }
    }, 
        handleCredentialResponse(response) {
      if (response.credential) {
        this.$store.dispatch('loginUser', response.credential)
          .then(() => {
            const redirect = this.$route.query.redirect || '/';
            this.$router.push(redirect);
          })
          .catch(error => {
            this.error = error.message || 'Login failed';
          });
      } else {
        this.error = 'Login failed';
      }
    },
    redirectToHome() {
      this.$router.push('/');
    }
  },
  mounted() {
    this.initGoogleSignIn()
  }
}

</script>
<style scoped>
@import '/ssr/css/second_style.css';
</style>
